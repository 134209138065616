html, body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.quill .ql-toolbar {
  border-color: var(--chakra-colors-gray-200) !important;
  border-top-left-radius: var(--chakra-radii-md);
  border-top-right-radius: var(--chakra-radii-md);
}

.quill .ql-container {
  border-color: var(--chakra-colors-gray-200) !important;
  border-bottom-left-radius: var(--chakra-radii-md);
  border-bottom-right-radius: var(--chakra-radii-md);
}

.invalid-quill .quill .ql-toolbar {
  border-color: #E53E3E !important;
  box-shadow: 0 0 0 1px #e53e3e;
}

.invalid-quill .quill .ql-container {
  border-color: #E53E3E !important;
  box-shadow: 0 0 0 1px #e53e3e;
}